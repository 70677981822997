
import Vue from 'vue';

import theme from 'Theme';

import FuturesOrderRow from './FuturesOrderRow.vue';

export default Vue.extend<any, any, any, any>({
    components: {
        FuturesOrderRow,
    },
    props: {
        orders: {
            type: Array,
            default: () => [],
        },
        showActiveOrders: {
            type: Boolean,
            default: false,
        },
        showClosedOrders: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
});
