const stringToFixedDigits = (value: string, digits: number): string => {
    if (digits === 0) {
        return value.split('.')[0];
    }

    const tempArr = value.split('.');
    if (!tempArr[1]) {
        let zerosStr = '';
        for (let i = 0; i < digits; i += 1) {
            zerosStr += '0';
        }
        return `${value === 'NaN' ? 0 : value}.${zerosStr}`;
    }
    const resFloatPart = tempArr[1];
    if (resFloatPart.length >= digits) {
        if (Number(resFloatPart.substring(0, digits)) === 0) {
            return `${tempArr[0] === 'NaN' ? 0 : tempArr[0]}.${resFloatPart.substring(0, digits - 2)}1`;
        }
        return `${tempArr[0] === 'NaN' ? 0 : tempArr[0]}.${resFloatPart.substring(0, digits)}`;
    }
    let zerosStr = '';
    for (let i = 0; i < digits - resFloatPart.length; i += 1) {
        zerosStr += '0';
    }
    return `${tempArr[0] === 'NaN' ? 0 : tempArr[0]}.${resFloatPart}${zerosStr}`;
};

export { stringToFixedDigits };
