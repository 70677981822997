
import Vue from 'vue';
import { mapGetters } from 'vuex';

import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';
import { setActiveTerminalAssetPairId, updateSpotAssetPairsList } from 'Store/v2/AssetPairs';
import theme from 'Theme';

import PositionRow from './PositionRow.vue';

export default Vue.extend<any, any, any, any>({
    components: {
        PositionRow,
    },
    props: {
        positions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            theme,
        };
    },
    computed: {
        ...mapGetters({
            currentPlacementId: 'Placements/activeTerminalPlacementId',
            currentAssetPairSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL',
        }),
    },
    methods: {
        async selectContractSymbol(placementName, symbol) {
            if (!this.$store.state.Placements.maintenanceStatuses.get(placementName)) {
                return;
            }

            const positionPlacementId = this.$store.getters['Placements/getPlacementIdByName'](placementName);
            if (positionPlacementId !== this.currentPlacementId) {
                await this.$store.dispatch('Placements/setActiveTerminalPlacementId', positionPlacementId);
                await this.$store.dispatch(updateSpotAssetPairsList({ placementId: positionPlacementId, isFirstDownload: false }));
            }
            const spotAssetPair = await this.getSpotAssetPair(symbol, placementName);
            await this.$store.dispatch(setActiveTerminalAssetPairId(spotAssetPair.id));
        },
        async getSpotAssetPair(symbol, placementName) {
            const { data: pair } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                placementName,
                symbol,
            }));
            return pair;
        },
    },
});
