<template>
    <tr :class="{ [theme.table.expandedRow]: isUnderOrder }">
        <td :class="[theme.table.alignLeft]">
            <PlacementIcon :class="[s.mrXs, s.alignSelfCenter]" :placement="trade.placementName" />
            <span :class="[s.alignSelfCenter, theme.table.placementContent]">{{ trade.placementName }}</span>
        </td>
        <td :class="[theme.table.alignCenter]">
            <Guid
                :class="s.mAuto"
                :guid="trade.id"
                is-short
                copied-value-name="Trade ID"
            />
        </td>
        <td
            :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, theme.table.gray]"
            v-if="!isUnderOrder"
        >
            {{ dateMaker(trade.orderInitiatorType === 'placement_subaccount_api_keys' ? trade.outerTimestamp : trade.createdAt) }}
        </td>
        <td
            :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, theme.table.gray]"
            v-if="isUnderOrder"
        >
            {{ dateMaker(trade.orderInitiatorType === 'placement_subaccount_api_keys' ? trade.outerTimestamp : trade.createdAt) }}
        </td>
        <td :class="[theme.table.alignCenter]" v-if="isUnderOrder"></td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent]" v-if="isUnderOrder">
            {{ trade.orderType }}
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, theme.table.gray]">
            {{ trade.baseAssetSymbol }}/{{ trade.quoteAssetSymbol }}
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, { [theme.table.red]: !trade.isBuy, [theme.table.green]: trade.isBuy }]">
            {{ trade.isBuy ? 'Buy' : 'Sell' }}
        </td>
        <td :class="[theme.table.alignCenter]" v-if="isUnderOrder && !isOtc"></td>
        <td :class="[theme.table.alignCenter]">
            <AssetValue
                :value="stringToFixedDigits(String(trade.price).replace(' ', ''), quoteAssetPrecision).getSeparatedDigits()"
                :asset="trade.quoteAssetSymbol"
                :need-to-round="false"
                :value-class="theme.table.ordersHistoryValueContent"
                is-aligned-center
            />
        </td>
        <td :class="[theme.table.alignCenter]">
            <AssetValue
                :value="stringToFixedDigits(String(trade.baseQuantity), baseAssetPrecision).getSeparatedDigits() || 0"
                :asset="trade.baseAssetSymbol"
                :need-to-round="false"
                :value-class="theme.table.ordersHistoryValueContent"
                is-aligned-center
            />
        </td>
        <td :class="[theme.table.alignCenter]">
            <AssetValue
                :value="stringToFixedDigits(String(trade.quoteQuantity), quoteAssetPrecision).getSeparatedDigits() || 0"
                :asset="trade.quoteAssetSymbol"
                :need-to-round="false"
                :value-class="theme.table.ordersHistoryValueContent"
                is-aligned-center
            />
        </td>
        <td v-if="!isOtc" :class="[theme.table.alignRight]">
            <AssetValue
                v-if="trade.commissionQuantity"
                :value="stringToFixedDigits(String(trade.commissionQuantity.noExponents()), feeAssetPrecision).getSeparatedDigits() || 0"
                :asset="trade.commissionAssetSymbol"
                :need-to-round="false"
                :value-class="theme.table.ordersHistoryValueContent"
                :is-aligned-right="!isUnderOrder"
                :is-aligned-center="isUnderOrder"
            />
            <span
                v-else
                :class="theme.table.ordersHistoryValueContent"
            >
                ...
            </span>
        </td>
        <td :class="[theme.table.alignLeft]" v-if="isUnderOrder"></td>
    </tr>
</template>

<script>
import { OrdersSides } from 'Models/trading';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Guid from 'UI/Guid.vue';

export default {
    name: 'TradeRow',
    components: {
        AssetValue,
        Guid,
    },
    props: {
        trade: {
            type: Object,
            required: true,
        },
        number: {
            type: Number,
            default: null,
        },
        isUnderOrder: {
            type: Boolean,
            default: false,
        },
        isOtc: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            OrdersSides,
            stringToFixedDigits,
            theme,
        };
    },
    computed: {
        baseAssetPrecision() {
            const baseAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.trade.baseAssetSymbol);
            if (!baseAssetData) {
                return 8;
            }
            if (baseAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
        quoteAssetPrecision() {
            const quoteAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.trade.quoteAssetSymbol);
            if (!quoteAssetData) {
                return 8;
            }
            if (quoteAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
        feeAssetPrecision() {
            const feeAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.trade.commissionAssetSymbol);
            if (!feeAssetData) {
                return 8;
            }
            if (feeAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
    },
    methods: {
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
    },
};
</script>

<style lang="postcss" module="s">
.alignSelfCenter {
    vertical-align: middle;
}
.mrXs {
    margin-right: var(--m-xs);
}
.mAuto {
    margin: auto;
}
</style>
