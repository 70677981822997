
import Vue from 'vue';

import theme from 'Theme';

import FuturesTradeRow from './FuturesTradeRow.vue';

export default Vue.extend<any, any, any, any>({
    components: {
        FuturesTradeRow,
    },
    props: {
        trades: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
});
