<template>
    <table :class="[theme.table.table, s.relative, s.w100, {[s.h100]: trades.length === 11 || isLoading}]">
        <thead>
        <tr>
            <th :class="[theme.table.alignLeft, theme.table.headerText]">Placement</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Trade ID</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Date</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Pair</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Act</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Price</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Qty</th>
            <th :class="[theme.table.alignCenter, theme.table.headerText]">Total</th>
            <th :class="[theme.table.alignRight, theme.table.headerText]">Fee</th>
        </tr>
        </thead>
        <tbody>
        <TradeRow
            v-for="(trade, index) in trades"
            :key="trade.id"
            :number="index + 1"
            :trade="trade"
        />
        </tbody>
        <Preloader
            v-if="isLoading"
            :class="s.preloader"
        />
    </table>
</template>

<script>
import theme from 'Theme';

import TradeRow from './TradeRow.vue';

export default {
    name: 'OrdersHistoryTable',
    components: {
        TradeRow,
    },
    props: {
        trades: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
};
</script>

<style lang="postcss" module="s">
.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
}
.relative {
    position: relative;
}
.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
