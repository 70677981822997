<template>
    <div>
        <table :class="[theme.table.table, s.relative, s.w100, {[s.h100]: (orders.length === 11 || isLoading)}]">
            <thead>
            <tr>
                <th :class="[theme.table.headerText, theme.table.alignLeft]">
                    Placement
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    ID
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Date
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    User
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Type
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Pair
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Act
                </th>
                <th v-if="!isOtc" :class="[theme.table.headerText, theme.table.alignCenter]">
                    Trigger
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    {{ isOtc ? 'Exec. Price' : 'Avg. Exec. Price' }}
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Exec. Qty
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    Exec. Total
                </th>
                <th v-if="!isOtc" :class="[theme.table.headerText, theme.table.alignCenter]">
                    Fee
                </th>
                <th :class="[theme.table.headerText, theme.table.alignRight]">
                    Status / Action
                </th>
            </tr>
            </thead>
            <OrderRow
                v-for="order in orders"
                :key="order.id"
                :order="order"
                :is-otc="isOtc"
            />
            <Preloader
                v-if="isLoading"
                :class="s.preloader"
            />
        </table>
    </div>
</template>

<script>
import theme from 'Theme';

import OrderRow from './OrderRow.vue';

export default {
    name: 'OrdersHistoryTable',
    components: {
        OrderRow,
    },
    props: {
        orders: {
            type: Array,
            required: true,
        },
        isOtc: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
};
</script>

<style lang="postcss" module="s">
.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
}
.relative {
    position: relative;
}
.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
