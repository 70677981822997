<template>
    <tbody
        v-tooltip.auto="{
            content: order.rejectReason,
            trigger: 'hover',
            autoHide: true,
        }"
        :class="s.w100"
    >
    <tr
        @click="toggleDetails"
    >
        <td :class="[theme.table.alignLeft, theme.table.placementContent]">
            <PlacementIcon :class="[s.mrXs, s.alignSelfCenter]" :placement="order.placementName"/>
            <span :class="s.alignSelfCenter">{{ order.placementName }}</span>
        </td>
        <td :class="[theme.table.alignCenter]">
            <Guid
                :class="s.mAuto"
                :guid="order && order.id ? order.id : ''"
                is-short
                copied-value-name="Order ID"
            />
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, theme.table.gray]">
            {{ dateMaker(order.createdAt) }}
        </td>
        <td :class="[theme.table.alignCenter]">
            <span
                v-if="order.initiatorType === 'placement_subaccount_api_keys'"
                :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent]"
            >
                Exchange API
            </span>
            <Guid
                v-else
                :class="s.mAuto"
                :guid="order && order.initiatorUserId ? order.initiatorUserId : ''"
                is-short
                copied-value-name="Order initiator ID"
            />
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent]">
            {{ resolveOrderType(order.orderType) }}
            {{ order.timeInForce ? order.timeInForce : '' }}
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, theme.table.gray]">
            {{ order.baseAssetSymbol }}/{{ order.quoteAssetSymbol }}
        </td>
        <td :class="[theme.table.alignCenter, theme.table.ordersTableBoldContent, { [theme.table.green]: order.isBuy, [theme.table.red]: !order.isBuy }]">
            {{ order.isBuy ? 'Buy' : 'Sell' }}
        </td>
        <td v-if="!isOtc" :class="[theme.table.alignCenter]">
            <AssetValue
                v-if="Number(order.triggerPrice)"
                :value="stringToFixedDigits(String(order.triggerPrice), quoteAssetPrecision).getSeparatedDigits()"
                :asset="order.quoteAssetSymbol"
                :value-class="theme.table.ordersHistoryValueContent"
                :need-to-round="false"
                is-aligned-center
            />
            <span :class="theme.table.ordersTableBoldContent" v-else>No</span>
        </td>
        <td :class="theme.table.alignCenter">
            <div :class="s.priceRow">
                <AssetValue
                    :value="stringToFixedDigits(String(order.priceAvg), quoteAssetPrecision).noExponents().getSeparatedDigits()"
                    :asset="order.baseAssetSymbol"
                    :value-class="theme.table.ordersHistoryValueContent"
                    :need-to-round="false"
                    is-aligned-center
                />
                <span v-if="order.status !== 'FILLED' && (order.orderType === 'LIMIT' || order.orderType === 'LIMIT_STOP')">
                    <AssetValue
                        :value="stringToFixedDigits(String(order.price), quoteAssetPrecision).noExponents().getSeparatedDigits()"
                        :asset="order.baseAssetSymbol"
                        :value-class="theme.table.ordersHistoryValueContentViolet"
                        :need-to-round="false"
                        is-aligned-center
                    />
                </span>
            </div>
        </td>
        <td :class="[theme.table.alignCenter]">
            <div :class="[s.priceRow]">
                <AssetValue
                    :value="stringToFixedDigits(String(order.executedBaseQuantity), baseAssetPrecision).getSeparatedDigits()"
                    :asset="order.baseAssetSymbol"
                    :value-class="theme.table.ordersHistoryValueContent"
                    :need-to-round="false"
                    is-aligned-center
                />
                <span v-if="order.status !== 'FILLED' && (order.orderType === 'LIMIT' || order.orderType === 'LIMIT_STOP')">
                    <AssetValue
                        :value="stringToFixedDigits(String(order.baseQuantity), baseAssetPrecision).getSeparatedDigits()"
                        :asset="order.baseAssetSymbol"
                        :value-class="theme.table.ordersHistoryValueContentViolet"
                        :need-to-round="false"
                        is-aligned-center
                    />
                </span>
            </div>
        </td>
        <td :class="[theme.table.alignCenter]">
            <div :class="[s.priceRow]">
                <AssetValue
                    :value="stringToFixedDigits(String(order.executedQuoteQuantity), quoteAssetPrecision).getSeparatedDigits()"
                    :asset="order.quoteAssetSymbol"
                    :value-class="theme.table.ordersHistoryValueContent"
                    :need-to-round="false"
                    is-aligned-center
                />
                <span v-if="order.status !== 'FILLED' && (order.orderType === 'LIMIT' || order.orderType === 'LIMIT_STOP')">
                    <AssetValue
                        :value="stringToFixedDigits(String(order.baseQuantity * order.price), quoteAssetPrecision).getSeparatedDigits()"
                        :asset="order.quoteAssetSymbol"
                        :value-class="theme.table.ordersHistoryValueContentViolet"
                        :need-to-round="false"
                        is-aligned-center
                    />
                </span>
            </div>
        </td>
        <td v-if="!isOtc" :class="[theme.table.alignCenter]">
            <AssetValue
                v-if="Number(order.commissionQuantity)"
                :value="stringToFixedDigits(String(order.commissionQuantity.noExponents()), feeAssetPrecision).getSeparatedDigits()"
                :asset="order.commissionAssetSymbol"
                :value-class="theme.table.ordersHistoryValueContent"
                :need-to-round="false"
                is-aligned-center
            />
            <span v-else>
                -
            </span>
        </td>
        <td :class="[theme.table.alignRight]">
            <span
                :class="[s.row, theme.table.ordersHistoryValueContent, { [theme.table.gray]: order.status.toLowerCase() !== 'filled' }]"
            >
                <Preloader v-if="order.status.toLowerCase() === 'registered' || isCancelOrderDisabled" :class="s.fade" />
                {{ $store.getters.localization.Body.Terminal.OrdersHistory.OrderStatuses[order.status] }}
            </span>
            <span
                v-if="isOrderStatusActive && isCancelable"
                :class="[theme.table.ordersHistoryValueContent, theme.table.violet]"
                @click.stop="cancelOrder"
            >
                Cancel
            </span>
        </td>
    </tr>
    <template v-if="isOpen">
        <TradeRow
            v-for="(trade, index) in (orderTrades || [])"
            :key="trade.id"
            :trade="trade"
            :number="index + 1"
            :is-otc="isOtc"
            is-under-order
        />
    </template>
    </tbody>
</template>

<script>
import { mapGetters } from 'vuex';

import TradingApi from 'Apis/Trading';
import { OrdersSides } from 'Models/trading';
import { OrdersStatuses } from 'Models/ordersHistory';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Guid from 'UI/Guid.vue';
import Preloader from 'Common/Preloader.vue';
import CancelOrderRequest from 'Entities/orderRegistrator/CancelOrderRequest';
import ApiError from 'Entities/ApiError';
import { resolveOrderType } from 'Store/v2/Futures';

import TradeRow from './TradeRow.vue';

export default {
    name: 'OrderRow',
    components: {
        TradeRow,
        AssetValue,
        Guid,
        Preloader,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        isOtc: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            OrdersSides,
            isOpen: false,
            isCancelOrderDisabled: false,
            NUMBERS_PRECISION: 100000000,
            stringToFixedDigits,
            resolveOrderType,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        orderTrades() {
            return this.$store.state.Orders.History.ordersTradesMap.get(`${this.order.accountId}, ${this.order.id}`);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        isOrderStatusActive() {
            return [OrdersStatuses.PARTIALLY_FILLED.value, OrdersStatuses.REGISTERED.value, OrdersStatuses.PLACED.value].indexOf(this.order.status) !== -1;
        },
        isCancelable() {
            return [OrdersStatuses.PARTIALLY_FILLED.value, OrdersStatuses.PLACED.value, OrdersStatuses.REGISTERED.value].indexOf(this.order.status) !== -1;
        },

        isTransparent() {
            return [OrdersStatuses.CANCELED.value, OrdersStatuses.REJECTED.value].indexOf(this.order.status) !== -1;
        },
        baseAssetPrecision() {
            const baseAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.order.baseAssetSymbol);
            if (!baseAssetData) {
                return 8;
            }
            if (baseAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
        quoteAssetPrecision() {
            const quoteAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.order.quoteAssetSymbol);
            if (!quoteAssetData) {
                return 8;
            }
            if (quoteAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
        feeAssetPrecision() {
            const feeAssetData = this.$store.state.Assets.assets.find(({ symbol }) => symbol === this.order.commissionAssetSymbol);
            if (!feeAssetData) {
                return 8;
            }
            if (feeAssetData.type === 'crypto-stable-coin') {
                return 4;
            }
            return 8;
        },
        progress() {
            return this.order.executedQuoteQuantity / this.order.quoteQuantity;
        },
    },
    methods: {
        showDetails() {
            this.isOpen = true;
        },
        hideDetails() {
            this.isOpen = false;
        },
        async toggleDetails() {
            if (this.isOpen) {
                this.hideDetails();
            } else {
                this.showDetails();
            }
        },
        async cancelOrder() {
            if (this.isCancelOrderDisabled || !this.isKycVerified) {
                return;
            }
            this.isCancelOrderDisabled = true;

            try {
                await TradingApi.privateOrdersCancelOrder(new CancelOrderRequest({ id: this.order.id }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error cancelling order');
                }
            } finally {
                this.isCancelOrderDisabled = false;
            }
        },
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
    },
    async mounted() {
        await this.$store.dispatch('Orders/History/getOrderTrades', { accountId: this.order.accountId, orderId: this.order.id });
    },
};
</script>

<style lang="postcss" module="s">
.mrXs {
    margin-right: var(--m-xs);
}
.alignSelfCenter {
    vertical-align: middle;
}
.w100 {
    width: 100%;
}
.row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: var(--m-s);
}
.fade {
    opacity: .4;
}
.priceRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: var(--m-xs);
    height: 100%;
}
.mAuto {
    margin: auto;
}
</style>
