<template>
    <div>
        <span
            v-for="(item, index) in items"
            :key="item.id"
            :class="[s.item, { [s.active]: index === value }]"
            @click="setActive(item, index)"
        >
            {{ item }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'BlockNavbar',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        value: {},
    },
    methods: {
        setActive(item, index) {
            if (!this.disabled) {
                this.$emit('change', index);
                this.$emit('setitem', {
                    item,
                    index,
                });
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.item {
    font-weight: var(--fw-regular);
    font-size: var(--fs-xl);
    line-height: var(--fs-xl);
    text-transform: uppercase;
    color: var(--cl-gray);
    margin-right: var(--m-xl);
    cursor: pointer;
    &.active {
        font-weight: var(--fw-bold);
        font-size: var(--fs-xl);
        line-height: var(--fs-xl);
        text-transform: uppercase;
        color: var(--cl-black);
    }
}
</style>
