
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { placeOrder } from 'Store/v2/Futures';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Shift from 'UI/Shift.vue';
import Button from 'Control/Button.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import { SET_POSITION, SET_STOP_LOSS_ORDER, SET_TAKE_PROFIT_ORDER } from 'Store/v2/TakeProfitStopLossModal';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import FuturesOrderPresenter from 'Entities/privatePresenter/FuturesOrderPresenter';
import FuturesPosition from 'Entities/privatePresenter/FuturesPosition';
import TradingApi from 'Apis/Trading';
import FuturesOrdersRequest from 'Entities/privatePresenter/FuturesOrdersRequest';
import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';

interface Data {
    timeoutId: null | ReturnType<typeof setTimeout>;
    isButtonDisabled: boolean;
    showTooltip: boolean;
    stringToFixedDigits: any;
    theme: any;
    activeTpOrder: FuturesOrderPresenter | undefined;
    activeSlOrder: FuturesOrderPresenter | undefined;
    pricePrecision: number;
    quantityPrecision: number;
}

interface Methods {
    startClosingProcess: () => void;
    closePosition: () => void;
    clearTimeoutId: () => void;
    setShowTooltip: () => void;
    openModal: () => void;
    getActiveTpOrder: () => void;
    getActiveSlOrder: () => void;
    getPrecisions: () => void;
}

interface Computed {
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        Shift,
        AssetValue,
        Button,
    },
    props: {
        position: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            timeoutId: null,
            isButtonDisabled: false,
            showTooltip: false,
            stringToFixedDigits,
            theme,
            activeTpOrder: undefined,
            activeSlOrder: undefined,
            pricePrecision: 4,
            quantityPrecision: 8,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            currentPlacementId: 'Placements/activeTerminalPlacementId',
            activePlacementName: 'Placements/activeTerminalPlacementName',
        }),
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
    methods: {
        async getActiveTpOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeTpOrder = undefined;
                    return;
                }

                const activePosition: FuturesPosition = this.position;
                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: activePosition.placementName,
                    positionSide: activePosition.side as 'SHORT' | 'LONG',
                    spotAssetPairSymbol: activePosition.contractSymbol,
                    accountId: activePosition.accountId,
                    type: 'TAKE_PROFIT',
                }));

                if (orders.length > 0) {
                    [this.activeTpOrder] = orders;
                } else {
                    this.activeTpOrder = undefined;
                }
            } catch {
                this.activeTpOrder = undefined;
            }
        },
        async getActiveSlOrder() {
            try {
                if (!this.activeAccountId) {
                    this.activeSlOrder = undefined;
                    return;
                }

                const activePosition: FuturesPosition = this.position;
                const { data: orders } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                    statuses: ['REGISTERED', 'PLACED', 'PARTIALY_FILLED'],
                    placementName: activePosition.placementName,
                    positionSide: activePosition.side as 'SHORT' | 'LONG',
                    spotAssetPairSymbol: activePosition.contractSymbol,
                    accountId: activePosition.accountId,
                    type: 'STOP_LOSS',
                }));
                if (orders.length > 0) {
                    [this.activeSlOrder] = orders;
                } else {
                    this.activeSlOrder = undefined;
                }
            } catch {
                this.activeSlOrder = undefined;
            }
        },
        startClosingProcess() {
            this.timeoutId = setTimeout(() => {
                this.closePosition();
            }, 1500);
        },
        async closePosition() {
            try {
                this.isButtonDisabled = true;
                await this.$store.dispatch(placeOrder({
                    accountId: this.$store.getters['Accounts/activeAccountID'],
                    contractSymbol: this.position.contractSymbol,
                    leverage: Number(this.position.leverage),
                    placementName: this.position.placementName,
                    positionSide: this.position.side,
                    quantity: String(this.position.quantity),
                    side: 'SELL',
                    type: 'MARKET',
                }));
            } finally {
                this.isButtonDisabled = false;
            }
        },
        clearTimeoutId() {
            if (!this.timeoutId) {
                return;
            }
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        },
        async openModal() {
            this.$store.commit(SET_POSITION(this.position));
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                if (this.activeTpOrder) {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(this.activeTpOrder));
                } else {
                    this.$store.commit(SET_TAKE_PROFIT_ORDER(null));
                }
                if (this.activeSlOrder) {
                    this.$store.commit(SET_STOP_LOSS_ORDER(this.activeSlOrder));
                } else {
                    this.$store.commit(SET_STOP_LOSS_ORDER(null));
                }
                this.$modal.show('TakeProfitStopLossModal');
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        setShowTooltip() {
            this.showTooltip = true;
        },
        async getPrecisions() {
            try {
                const { data: assetPair } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                    placementName: this.position?.placementName ?? '',
                    symbol: this.position?.contractSymbol ?? '',
                }));
                this.quantityPrecision = assetPair.placementPrecisionQuantity ?? 8;
                this.pricePrecision = assetPair.placementPrecisionPrice ?? 4;
            } catch {
                this.quantityPrecision = 8;
                this.pricePrecision = 4;
            }
        },
    },
    created() {
        window.addEventListener('mouseup', () => {
            this.clearTimeoutId();
        });
        window.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (!path.some(({ classList }) => classList && Array.from(classList).includes('hold-button'))) {
                this.showTooltip = false;
            }
        });
    },
    async mounted() {
        await this.getActiveTpOrder();
        await this.getActiveSlOrder();
        await this.getPrecisions();
    },
    watch: {
        async position() {
            await this.getActiveTpOrder();
            await this.getActiveSlOrder();
        },
    },
});
