/* eslint-disable max-classes-per-file */
/**
 * @typedef {Object} RecordPlacement
 *
 * @property {Number} id
 * @property {String} name
 */

/**
 * @typedef {Object} RecordType
 *
 * @property {String} value
 */

/**
 * @typedef {Object} OrderStatus
 *
 * @property {String} value
 */

/**
 * @typedef {Object} RecordDate
 *
 * @property {String} label - human readable string
 * @property {Date} value
 */

/**
 * @typedef {Object} RecordAsset
 *
 * @property {Number} id - asset id
 * @property {String} symbol - asset symbol
 */

/**
 * @typedef {Object} RecordPair
 *
 * @property {Number} id - pair id
 * @property {String} symbol - pair symbol
 */

/**
 * @typedef {Object} RecordFee
 *
 * @property {String|Number} quantity
 * @property {String} assetSymbol
 */

/**
 * @typedef {Class} HistoryTradeRecord
 *
 * @property {Number} id
 * @property {RecordPlacement} placement
 * @property {RecordType} type
 * @property {RecordDate} date
 * @property {RecordAsset} baseAsset
 * @property {RecordAsset} quoteAsset
 * @property {RecordPair} pair
 * @property {OrderSide} action
 * @property {String} price
 * @property {String} quantity
 * @property {String} total
 * @property {RecordFee} fee
 *
 * @property {Object} apiTradeData
 *
 */

/**
 * @typedef {Class} HistoryOrderRecord
 *
 * @property {Number} id
 * @property {RecordPlacement} placement
 * @property {String} initiatorGuid
 * @property {RecordType} type
 * @property {RecordDate} date
 * @property {RecordAsset} baseAsset
 * @property {RecordAsset} quoteAsset
 * @property {RecordPair} pair
 * @property {OrderSide} action
 * @property {String} price
 * @property {String} priceAvg
 * @property {String} quantity
 * @property {String} executedQuantity
 * @property {String} executedTotal
 * @property {Number} progress - number in range 0-1
 * @property {Array.<String>} allowedActions
 * @property {RecordFee} fee
 * @property {OrderStatus} status
 *
 * @property {Array.<HistoryTradeRecord>} trades
 * @property {Boolean} isTradesReady - `true` if trades was loaded and `status.isActive === false`
 *
 * @property {Object} apiOrderData
 */

import store from '@/store';
import { TradingOrdersTypes, OrdersSides, OrderExpiries } from 'Models/trading';

/**
 * @type {Object.<String, OrderType>}
 */
export const OrdersTypes = {
    MARKET: {
        value: TradingOrdersTypes.MARKET.value,
        field: 'MARKET',
    },
    LIMIT: {
        value: TradingOrdersTypes.LIMIT.value,
        field: 'LIMIT',
    },
    LIMIT_STOP: {
        value: TradingOrdersTypes.STOP_LIMIT.value,
        field: 'STOP_LIMIT',
    },
    MARKET_STOP: {
        value: TradingOrdersTypes.STOP_MARKET.value,
        field: 'STOP_MARKET',
    },
};

/**
 * @type {Object.<String, OrderStatus>}
 */
export const OrdersStatuses = {
    REGISTERED: {
        value: 'REGISTERED',
        field: 'REGISTERED',
        isActive: true,
    },
    PLACED: {
        value: 'PLACED',
        field: 'PLACED',
        isActive: true,
    },
    PARTIALLY_FILLED: {
        value: 'PARTIALLY_FILLED',
        field: 'PARTIALLY_FILLED',
        isActive: true,
    },
    FILLED: {
        value: 'FILLED',
        field: 'FILLED',
        isActive: false,
    },
    CANCELED: {
        value: 'CANCELED',
        field: 'CANCELED',
        isActive: false,
    },
    REJECTED: {
        value: 'REJECTED',
        field: 'REJECTED',
        isActive: false,
    },
};

export class Trade {
    id

    placement

    type

    date

    baseAsset

    quoteAsset

    pair

    action

    price

    quantity

    total

    apiTradeData

    baseQuantity: any;

    quoteQuantity: any;

    fee: { quantity: any; assetSymbol: any; };

    constructor(trade) {
        const tradeDate = new Date(trade.createdAt);

        this.id = trade.id;

        this.placement = {
            id: trade.placementId,
            name: trade.placementName,
        };

        this.type = OrdersTypes[trade.orderType];

        this.date = {
            label: store.getters.getTimeDateString({ timestamp: tradeDate }),
            value: tradeDate,
        };

        this.baseAsset = {
            id: trade.baseAssetId,
            symbol: trade.baseAssetSymbol,
        };
        this.quoteAsset = {
            id: trade.quoteAssetId,
            symbol: trade.quoteAssetSymbol,
        };
        this.pair = {
            id: trade.spotAssetPairId,
            symbol: trade.spotAssetPairSymbol,
        };

        this.action = trade.isBuy ? OrdersSides.BUY : OrdersSides.SELL;
        this.price = trade.price ? trade.price.getSeparatedDigits() : 0;

        this.baseQuantity = trade.baseQuantity ? trade.baseQuantity.getSeparatedDigits() : 0;
        this.quoteQuantity = trade.quoteQuantity ? trade.quoteQuantity.getSeparatedDigits() : 0;

        this.fee = {
            quantity: trade.commissionQuantity,
            assetSymbol: trade.commissionAssetSymbol,
        };

        this.apiTradeData = trade;
    }
}

/**
 * @type {HistoryOrderRecord}
 */
export class Order {
    id

    placement

    initiatorGuid

    type

    date

    baseAsset

    quoteAsset

    pair

    action

    triggerPrice

    price

    priceAvg

    quantity

    executedQuantity

    executedTotal

    progress

    allowedActions

    fee

    status

    trades

    isTradesReady

    apiOrderData

    updatedAt: any;

    baseQuantity: any;

    executedBaseQuantity: any;

    quoteQuantity: any;

    executedQuoteQuantity: any;

    timeInForce: any;

    rejectReason: any;

    constructor(order) {
        const orderDate = new Date(order.createdAt);

        this.id = order.id;

        this.placement = {
            id: order.placementId,
            name: order.placementName,
        };

        this.initiatorGuid = order.initiatorUserId;

        this.type = OrdersTypes[order.orderType];
        this.date = {
            label: store.getters.getTimeDateString({ timestamp: orderDate }),
            value: orderDate,
        };
        this.updatedAt = order.updatedAt;
        this.baseAsset = {
            id: order.baseAssetId,
            symbol: order.baseAssetSymbol,
        };
        this.quoteAsset = {
            id: order.quoteAssetId,
            symbol: order.quoteAssetSymbol,
        };
        this.pair = {
            id: order.spotAssetPairId,
            symbol: order.spotAssetPairSymbol,
        };
        this.action = order.isBuy ? OrdersSides.BUY : OrdersSides.SELL;

        this.triggerPrice = order.triggerPrice;

        this.price = order.price ? order.price : 0;
        this.priceAvg = order.priceAvg ? order.priceAvg : 0;

        this.baseQuantity = order.baseQuantity ? order.baseQuantity : 0;
        this.executedBaseQuantity = order.executedBaseQuantity ? order.executedBaseQuantity : 0;

        this.quoteQuantity = order.quoteQuantity ? order.quoteQuantity : 0;
        this.executedQuoteQuantity = order.executedQuoteQuantity ? order.executedQuoteQuantity : 0;

        this.progress = order.isBuy && this.type.value === OrdersTypes.MARKET.value
            ? +order.executedQuoteQuantity / +order.quoteQuantity
            : +order.executedBaseQuantity / +order.baseQuantity;
        if (this.progress === Infinity) {
            this.progress = 1;
        }

        this.allowedActions = ['CANCEL'];

        this.fee = {
            quantity: order.commissionQuantity,
            assetSymbol: order.commissionAssetSymbol,
        };

        this.status = OrdersStatuses[order.status];

        this.timeInForce = OrderExpiries[order.timeInForce];

        this.rejectReason = order.rejectReason;

        this.trades = [];
        this.isTradesReady = false;

        this.apiOrderData = order;
    }

    isTradeAlreadyExist(tradeId) {
        return this.trades.some(({ id }) => id === tradeId);
    }

    addTrade(trade, adaptRecord = true) {
        if (!this.isTradeAlreadyExist(trade.id)) { this.trades.push(adaptRecord ? new Trade(trade) : trade); }
    }

    setTrades(trades, adaptRecords = true) {
        this.trades = [];

        trades.forEach((trade) => {
            this.addTrade(trade, adaptRecords);
        });
    }

    setTradesReady(value = true) {
        this.isTradesReady = value;
    }

    get isActive() {
        return this.status ? this.status.isActive : false;
    }
}
