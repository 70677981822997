
import Vue from 'vue';

import { OrdersSides } from 'Models/trading';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import { QUANTITY_TYPES, resolveOrderType } from 'Store/v2/Futures';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Guid from 'UI/Guid.vue';

export default Vue.extend<any, any, any, any>({
    components: {
        AssetValue,
        Guid,
    },
    props: {
        trade: {
            type: Object,
            required: true,
        },
        number: {
            type: Number,
            default: null,
        },
        isUnderOrder: {
            type: Boolean,
            default: false,
        },
        order: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            OrdersSides,
            QUANTITY_TYPES,
            PRECISION: 100000000,
            stringToFixedDigits,
            theme,
            resolveOrderType,
        };
    },
    methods: {
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            if (!date) {
                return '';
            }
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
    },
});
