
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import Shift from 'UI/Shift.vue';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';

interface Data {
    calculatePrecision: any;
    theme: any;
}

interface Methods {
    dateMaker: (data: string) => string;
}

interface Computed {
    quotationAssetSymbol: string;
    quotationPrecision: number;
    quantityValue: string;
    quotedQuantityValue: string;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        Shift,
    },
    props: {
        funding: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            calculatePrecision,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        quotationPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        quantityValue() {
            return Number(this.funding.quantity).toFixed(4);
        },
        quotedQuantityValue() {
            return Number(this.funding.quotedQuantity).toFixed(this.calculatePrecision(this.quotationAssetSymbol, true));
        },
    },
    methods: {
        dateMaker(dateStr) {
            if (!dateStr) {
                return '';
            }
            const date = new Date(dateStr);
            if (!date) {
                return '';
            }
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
    },
});
